import SectionHeader from '../components/sections/header';
import SectionBannerBlock from '../components/sections/banner-block';
import SectionStory from '../components/sections/story';
import SectionPlain from '../components/sections/plain';
import SectionQuote from '../components/sections/quote';
import SectionInstagram from '../components/sections/instagram';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql, Script } from 'gatsby';

import { IPage } from '../models/page.model';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import UpwardsButton from '../components/molecules/upwards-button';

import { structuredGeneralData, structuredLocalBusinessData } from '../config/structured-data';

const structuredGeneralDataString = JSON.stringify(structuredGeneralData);
const structuredLocalBusinessDataString = JSON.stringify(structuredLocalBusinessData);

interface IIndexProps {
    readonly data: {
        page: IPage;
    };
}

const Index: React.FC<IIndexProps> = ({ data }) => {
    const { page } = data;

    return (
        <MainLayout>
            <Script type="application/ld+json">{structuredGeneralDataString}</Script>
            <Script type="application/ld+json">{structuredLocalBusinessDataString}</Script>
            {page.sections.map((section) => {
                return (
                    <SectionFactory
                        key={`section-${section.type}-${section.sectionId}`}
                        SectionComponent={sectionComponents[section.type]}
                        section={section}
                        TitleTag={section.isMain ? 'h1' : 'h2'}
                    />
                );
            })}
            <UpwardsButton />
        </MainLayout>
    );
};

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
    }
`;

export default Index;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header': SectionHeader,
    'banner-block': SectionBannerBlock,
    'story': SectionStory,
    'plain': SectionPlain,
    'quote': SectionQuote,
    'instagram': SectionInstagram,
    'footer': SectionFooter,
};